$white: #fff;
$translucent: rgba(255, 255, 255, 0.5);
$green: #09606b;
$blue: #096099;
$calloutBrown: #c59953;

// admonitions

.post .admonitionblock td.icon [class^="fa icon-"] {
    font-size:2.5em;
    text-shadow:1px 1px 2px rgba(0,0,0,.5);
    cursor:default;
}

.post .admonitionblock td.icon .icon-note::before{content:"\f129";color:$green}
.post .admonitionblock td.icon .icon-tip::before{content:"\f0eb";color:$green}
.post .admonitionblock td.icon .icon-warning::before{content:"\f071";color:#bf6900}
.post .admonitionblock td.icon .icon-caution::before{content:"\f06d";color:#bf3400}
.post .admonitionblock td.icon .icon-important::before{content:"\f06a";color:#bf0000}
  
.post td.icon {
    width: 80px !important;
    text-align: center;
}
  
.post div.warning, div.important {
    padding: 0;
    border-right: none;
    border-left: none;
}
  
// caption for blocks, like code, rendered above the figure
  
.post .listingblock .content {
    max-width: 100%;
    margin: 0;
    padding: 0;
}
  
.post .listingblock .title{
    text-align: left !important;
    font-style: italic !important;
    font-weight: 500 !important;
    color: $green !important;
    margin-top: 5px !important;
    margin-bottom: -15px !important;
}
  
// image blocks
  
.post .imageblock {
    margin-bottom: 1em;
    display: block;
}
  
.post .imageblock .content {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
  
.post .imageblock img {
    max-width: 100%;
    margin-bottom: 0;
}
  
.post .imageblock .title {
    display: block !important;
    text-align: left !important;
    font-style: italic !important;
    font-weight: 500 !important;
    color: $green !important;
    margin-top: 0px !important;
}

// tableblocks

.tableblock .content {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.halign-right {
    text-align: right !important;
}

.halign-left {
    text-align: left !important;
}

.halign-center {
    text-align: center !important;
}

.valign-center {
    vertical-align: center !important;
}

.valign-bottom {
    vertical-align: bottom !important;
}

.valign-top {
    vertical-align: top !important;
}

// callouts
  
.post .listingblock .callout-list {
    margin-block-start: 5px;
}
  
.post .conum {
    box-sizing: border-box;
    background-size: auto;
    background-clip: border-box;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}
  
.post .conum[data-value] {
    display: inline-block;
    text-align: center;
    width: 1.67em;
    height: 1.67em;
    line-height: 1.67em;
    font-size: .75em;
    font-style: normal;
    font-weight: bold;
    color: $white !important;
    background: $calloutBrown;
}
  
.post pre .conum[data-value] {
    position: relative;
    top: -.125em;
}
  
.post .conum[data-value]+b {
    display: none;
}
  
.post .conum[data-value]::after {
    content: attr(data-value)
}
  
.post .colist td:not([class]):first-child {
    padding: .6em .75em 0;
    line-height: 1;
    vertical-align: top;
    width: 1.2rem;
}
  
.post .colist td:not([class]):last-child {
    padding: .25em 0;
}
  
.post .colist table, .post .colist tr, .post .colist td {
    border: none !important;
    background: none !important;
}
  
// anchors
  
.post h2 .link, .post h3 .link, .post h4 .link, .post h4.link, .post h5 .link, .post h6 .link {
    text-decoration-line: none !important;
}
  
.post h1>a.anchor, .post h2>a.anchor, .post h3>a.anchor, .post h4>a.anchor, .post h5>a.anchor, .post h6>a.anchor {
    position: absolute;
    z-index: 1001;
    width: 1.5ex;
    margin-left: -1.5ex;
    display: block;
    text-decoration: none !important;
    visibility: hidden;
    text-align: center;
    font-weight: 400;
}
  
.post h1>a.anchor::before, .post h2>a.anchor::before, .post h3>a.anchor::before, .post h4>a.anchor::before, .post h5>a.anchor::before, .post h6>a.anchor::before {
    content: "\00A7";
    font-size: .85em;
    display: block;
    padding-top: .1em;
}
  
// TODO ver como mostrar o símbolo da âncora
// h2 a.link:hover < h2 a.anchor {
//   visibility: visible;
// }
  